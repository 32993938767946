var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _vm._l(_vm.colors, function(color, i) {
            return [
              _vm._l(_vm.variants, function(variant, j) {
                return [
                  _c(
                    "v-toolbar",
                    {
                      key: i + "-" + j,
                      ref: "color-" + color + " " + variant,
                      refInFor: true,
                      staticClass: "mb-2",
                      attrs: {
                        color: color + " " + variant,
                        dark: variant.search("darken") != -1
                      }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "text-h6 font-weight-regular" },
                        [
                          _vm._v(
                            _vm._s(color) +
                              " - " +
                              _vm._s(variant) +
                              " color: " +
                              _vm._s(_vm.getHexValue(color, variant))
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }