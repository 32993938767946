export default {
	methods: {
		// color can be "primary darken" or "alternate1 lighten"
		themePalette(color) {
			// Default the style to darken if not specified
			const style =
				color.split(' ').length > 1 ? color.split(' ').pop() : 'darken'
			if (color.split(' ').length > 1) {
				color = color.split(' ').shift()
			}

			let colors = [color]
			for (let i = 0; i < 5; i++) {
				if (i === 0) {
					const inverse = style === 'darken' ? 'lighten' : 'darken'
					// add the inverse for the first element in the color palette
					colors.push(`${color} ${inverse}-1`)
				} else {
					colors.push(`${color} ${style}-${i}`)
				}
			}
			return this.generateColorThemePalette(colors)
		},

		themeColor(color) {
			// transform color compatibility
			if (color.split(' ').length === 1) {
				color += '-base'
			} else {
				color = color.replace('-', '').replace(' ', '-')
			}
			const colorObj = this.themeColors().find((item) => {
				return Object.keys(item).includes(color)
			})

			return colorObj ? colorObj[Object.keys(colorObj)[0]] : ''
		},
		// Filter can be terms seperated by space i.e. "primary lighten"
		themeColors(filter = null) {
			const styleSheet = document.getElementById(
				'vuetify-theme-stylesheet'
			)
			console.log(styleSheet)

			const colors = styleSheet.innerHTML
				.replace(/\n/g, '')
				.split(';')
				.map((val) => {
					let data = {}
					if (val.split(':').length > 1) {
						data[
							val
								.split(':')[0]
								.trim()
								.replace('--v-', '')
						] = val.split(':')[1].trim()
					}
					return data
				})
			return filter
				? colors
						.filter((item) => {
							if (Object.keys(item).length > 0) {
								return this.findColorFilteredItem(item, filter)
							}
							return false
						})
						.sort((a, b) => Object.keys(a)[0] < Object.keys(b)[0])
						.map((item) => item[Object.keys(item)[0]])
				: colors
		},
		findColorFilteredItem(item, filter) {
			let shouldInclude = false
			filter.split(' ').forEach((filter) => {
				if (
					Object.keys(item)[0].search(filter) !== -1 &&
					Object.keys(item)[0].split('-').length === 2
				) {
					shouldInclude = true
				}
			})
			return shouldInclude
		},
		/**
		 * Returns a color palette array of hex values from an array of vuetify colors
		 * @param {array} colors
		 */
		generateColorThemePalette(colors) {
			return colors.map((color) => this.themeColor(color))
		},
	},
}
